<div
  class="tw-flex tw-h-screen tw-w-screen tw-flex-col tw-bg-night tw-p-3 tw-text-center tw-text-white"
>
  <header class="tw-mb-10 tw-flex tw-items-center tw-justify-center">
    <a href="https://www.humanmademachine.com" class="tw-block">
      <div class="tw-hidden tw-h-20 tw-w-20 sm:tw-block">
        <img
          alt="Human Made Machine"
          src="https://assets.humanmademachine.com/hmm/logos/signature-logo-lockup.png"
        />
      </div>
      <div class="tw-block tw-h-12 tw-w-12 sm:tw-hidden">
        <img
          alt="Human Made Machine"
          src="https://assets.humanmademachine.com/hmm/logos/white/brand/HMM_Marque_White.svg"
        />
      </div>
    </a>
  </header>
  <div class="tw-flex tw-flex-grow tw-justify-center">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <div class="tw-flex tw-items-end tw-justify-between">
      <p class="tw-text-muted tw-m-0 tw-py-2">
        &copy;{{ year }} Human Made Machine
      </p>

      <img
        width="45px"
        width="45px"
        src="https://assets.humanmademachine.com/hmm/logos/white/brand/HMM_Marque_White.svg"
      />
    </div>
  </footer>
</div>
